import { DefaultTheme } from "styled-components";

export const dark: DefaultTheme = {
  mode: "dark",
  colors: {
    primary: "#000",
    secondary: "#121A25",
    text: "#b1b1b1",
    more: "#a7a7a7",
    signalGreen: "#004747",
    signalRed: "#810011",
    signalBlue: "#084470",
    signalYellow: "#e3890a",
    signalGrey: "#423f40",
  },
  input: {
    color: "#878b92",
    borderColor: "#373c50",
    focusBorderColor: "#e14eca",
    bgColor: "#394353",
  },
  body: {
    backgroundColor: "#000000",
  },
  sidebar: {
    backgroundColor: "#101318",
    color: "#b1b1b1",
  },
  menu: {
    menuContent: "#091b2d",
    icon: "#59d0ff",
    hover: {
      backgroundColor: "#0e3052",
      color: "#b6c8d9",
    },
    active: {
      backgroundColor: "#13395e",
      color: "#b6c8d9",
    },
    disabled: {
      color: "#3e5e7e",
    },
  },
  checkbox: {
    controlColor: "#e14eca",
  },
  add: {
    backgroundColor: "#C30034",
    boxColor: "rgba(255, 0, 0, 0.2)",
  },
  tab: {
    _selected: "#65a4e4",
    borderColor: "#373c50",
    color: "#a7a6a8",
    bg: "#1c1c1c",
  },
  status: {
    active: "#78bc82",
    inactive: "#c30034",
  },
  priority: {
    high: "#ff0000",
    medium: "#ffa500",
    low: "#008000",
    none: "#c30034",
  },
  table: {
    color: "#878b92",
    body: {
      backgroundColor: "#000",
    },
    th: {
      color: "#b1b1b1",
      borderColor: "#373c50",
      backgroundColor: "#101318",
    },
    action: "878b92",
    footer: {
      color: "#000000",
    },
  },
  feed: {
    title: {
      color: "#9f9f9f",
    },
    color: "#9e9e9e",
    footer: {
      color: "#4c4c4c",
    },
    action: {
      icon: "",
      menu: {
        menuContent: "#c4c4c4",
        menuItem: "#2D3748",
        menuItemHover: "#333E50",
      },
    },
  },
  scroll: {
    borderColor: "#373f50",
    backgroundColor: "#201f1f",
  },
  card: {
    borderColor: "#202020",
  },
  emBackground: "#43010F",
  emColor: "#9E9E9E", //"#5D3FD3",
  modal: {
    backgroundColor: "2d3748",
    fontColor: "#FFF",
  },
  panel: {
    backgroundColor: "#201C1C",
  },
  toolbar: {
    backgroundColor: "#0F0F0F",
    color: "#FFFFFF",
  },
  miniPost: {
    backgroundColor: "gray.700",
  },
  actionButton: {
    activeColor: "#FFFFFF",
    inactiveColor: "#7a7a7a",
    save: {
      backgroundColor: "#0080b1",
      color: "#FFFFFF",
    },
    cancel: {
      backgroundColor: "#009900",
      color: "#FFFFFF",
    },
    activate: {
      backgroundColor: "#006600",
      color: "#FFFFFF",
    },
    deactivate: {
      backgroundColor: "#CC633A",
      color: "#FFFFFF",
    },
    delete: {
      backgroundColor: "#CC0000",
      color: "#FFFFFF",
    },
  },
  container: {
    backgroundColor: "#000000",
  },
  customSelect: {
    fontSize: "1em",
    color: "#FFFFFF",
  },
  customInput: {
    fontSize: "1em",
    color: "#FFFFFF",
  },
  mapboxgl: {
    styleUrl: 'mapbox://styles/signalmapbox/cm063e5nu00jk01rbfyxuhwtn',
    fontColor: '#ecedf9',
    haloColor: '#323943',
    slots: {
      bottom: 'aeroway-polygon',
      middle: 'waterway-label',
      top: 'poi-label'
    }
  },
};

export const light: DefaultTheme = {
  mode: "light",
  colors: {
    primary: "#000",
    secondary: "#EBE6E6",
    text: "white",
    more: "#a7a7a7",
  },
  input: {
    color: "#000000",
    borderColor: "#373c50",
    focusBorderColor: "#e14eca",
    bgColor: "#FFF",
  },
  body: {
    backgroundColor: "#EBE6E6",
  },
  sidebar: {
    backgroundColor: "#4A4B51",
    color: "white",
  },
  menu: {
    menuContent: "#616266",
    icon: "#59d0ff",
    hover: {
      backgroundColor: "#58585D",
      color: "#FFFFFF",
    },
    active: {
      backgroundColor: "#000000",
      color: "#FFFFFF",
    },
    disabled: {
      color: "#3e5e7e",
    },
  },
  checkbox: {
    controlColor: "#e14eca",
  },
  add: {
    backgroundColor: "#C30034",
    boxColor: "#e8e3e3",
  },
  tab: {
    _selected: "#65a4e4",
    borderColor: "#373f50",
    color: "#a7a6a8",
    bg: "#1c1c1c",
  },
  status: {
    active: "#78bc82",
    inactive: "#c30034",
  },
  priority: {
    high: "#ff0000",
    medium: "#ffa500",
    low: "#008000",
    none: "#c30034",
  },
  table: {
    color: "#000000",
    body: {
      backgroundColor: "#EBE6E6",
    },
    th: {
      color: "#000000",
      borderColor: "#CBC5C5",
      backgroundColor: "#F2EBEB",
    },
    action: "878b92",
    footer: {
      color: "#EBE6E6",
    },
  },
  feed: {
    title: {
      color: "#9b9b9b",
    },
    color: "#000000",
    footer: {
      color: "#807979",
    },
    action: {
      icon: "#d3d3d3",
      menu: {
        menuContent: "#091b2d",
        menuItem: "#FAFAFA",
        menuItemHover: "#F1EEEE",
      },
    },
  },
  scroll: {
    borderColor: "#373f50",
    backgroundColor: "#201f1f",
  },
  card: {
    borderColor: "#202020",
  },
  emBackground: "#FFFF00",
  emColor: "#000000", //"#5D3FD3",
  modal: {
    backgroundColor: "#d8d8d8",
    fontColor: "#0d0d0f",
  },
  panel: {
    backgroundColor: "#F4F0F0",
  },
  toolbar: {
    backgroundColor: "#1a94da", // Same as colorScheme="twitter"
    // backgroundColor: "#3398CB",
    // color: "#5D5D5D"
    color: "#FFFFFF",
  },
  miniPost: {
    backgroundColor: "#EBE6E6",
  },
  actionButton: {
    activeColor: "#000000",
    inactiveColor: "#a0a0a0",
    save: {
      backgroundColor: "#009900",
      color: "#FFFFFF",
    },
    cancel: {
      backgroundColor: "#009900",
      color: "#FFFFFF",
    },
    activate: {
      backgroundColor: "#009900",
      color: "#FFFFFF",
    },
    deactivate: {
      backgroundColor: "#E36F41",
      color: "#FFFFFF",
    },
    delete: {
      backgroundColor: "#FF1919",
      color: "#FFFFFF",
    },
  },
  container: {
    backgroundColor: "#EBE6E6",
  },
  customSelect: {
    fontSize: "1em",
    color: "#5D5D5D",
  },
  customInput: {
    fontSize: "1em",
    color: "#000000",
  },
  mapboxgl: {
    styleUrl: 'mapbox://styles/signalmapbox/cm062mcl900k501pn8q23aa9e',
    fontColor: '#0a0b0f',
    haloColor: '#ffffff',
    slots: {
      bottom: 'aeroway-polygon',
      middle: 'waterway-label',
      top: 'poi-label'
    }
  },
};